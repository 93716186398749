export default {
  DarkCoral: "#F87A3A", //Secondary
  Highlight: "FFF2E5",
  Grun: "#005654",
  Text: "#282D2D",
  Mint: "#00E091",
  Cobalt: "#3F6AFB",
  AccentCoral: "#FF9B5C",
  AccentLightCoral: "#FFCA98",
  Grey: {
    v1: "#545757",
    v2: "#7D8282",
    v3: "#A8ABAB",
    v4: "#D4D6D6",
    v5: "#EBEBEB",
    v6: "#E5EDED",
    v7: "#F5F5F5", //test
    v8: "#F2F7F7", //test
  },
  Supplemental: {
    Error: "#C51b24",
    Warning: "#FFD540",
    Success: "#3F8924",
    Info: "#006FB9",
  },
  Legacy: {
    berry: {
      50: "#f3e4ec",
      100: "#e2bcd0",
      200: "#cf8fb1",
      300: "#bb6292",
      400: "#ad417a",
      500: "#9e1f63",
      600: "#961b5b",
      700: "#8c1751",
      800: "#821247",
      900: "#700a35",
      A100: "#ffa1c4",
      A200: "#ff6ea4",
      A400: "#ff3b84",
      A700: "#ff2274",
      contrastDefaultColor: "light",
    },

    mist: {
      50: "#f3f3f4",
      100: "#cccdce",
      200: "#b0b1b3",
      300: "#8c8d90",
      400: "#7c7d81",
      500: "#6d6e71",
      600: "#5e5f61",
      700: "#4f5052",
      800: "#404042",
      900: "#313133",
      A100: "#e9ecf4",
      A200: "#a6b1d1",
      A400: "#757f9c",
      A700: "#737884",
      contrastDefaultColor: "light",
    },

    indigo: {
      50: "#e0eef7",
      100: "#b3d4ea",
      200: "#80b7dc",
      300: "#4d9ace",
      400: "#2685c4",
      500: "#006fb9",
      600: "#0067b2",
      700: "#005caa",
      800: "#0052a2",
      900: "#004093",
      A100: "#bed6ff",
      A200: "#8bb5ff",
      A400: "#5894ff",
      A700: "#3f84ff",
      contrastDefaultColor: "light",
    },

    red: {
      50: "#f9e4e4",
      100: "#f0bcbc",
      200: "#e68f8f",
      300: "#db6262",
      400: "#d44141",
      500: "#cc1f1f",
      600: "#c71b1b",
      700: "#c01717",
      800: "#b91212",
      900: "#ad0a0a",
      A100: "#ffdada",
      A200: "#ffa7a7",
      A400: "#ff7474",
      A700: "#ff5a5a",
      contrastDefaultColor: "light",
    },

    amber: {
      50: "#faf3e6",
      100: "#f2e2c0",
      200: "#e9cf96",
      300: "#e0bb6c",
      400: "#daad4d",
      500: "#d39e2d",
      600: "#ce9628",
      700: "#c88c22",
      800: "#c2821c",
      900: "#b77011",
      A100: "#fff4e6",
      A200: "#ffdcb3",
      A400: "#ffc580",
      A700: "#ffb967",
      contrastDefaultColor: "dark",
    },

    green: {
      50: "#ffffff",
      100: "#ffffff",
      200: "#ffffff",
      300: "#f8fbf4",
      400: "#e9f3de",
      500: "#daebc7",
      600: "#cbe3b0",
      700: "#bcdb9a",
      800: "#add383",
      900: "#9ecb6d",
      A100: "#ffffff",
      A200: "#ffffff",
      A400: "#f3faeb",
      A700: "#e6f3d9",
      contrastDefaultColor: "dark",
    },

    cherry: {
      50: "#fde8e7",
      100: "#fac6c3",
      200: "#f7a09b",
      300: "#f47a72",
      400: "#f15e54",
      500: "#ef4136",
      600: "#ed3b30",
      700: "#eb3229",
      800: "#e82a22",
      900: "#e41c16",
      A100: "#ffffff",
      A200: "#ffe2e1",
      A400: "#ffb0ae",
      A700: "#ff9795",
      contrastDefaultColor: "light",
    },

    fruitpunch: {
      50: "#fbe4eb",
      100: "#f4bbce",
      200: "#ed8eae",
      300: "#e5608d",
      400: "#e03e74",
      500: "#da1c5c",
      600: "#d61954",
      700: "#d0144a",
      800: "#cb1141",
      900: "#c20930",
      A100: "#ffedf0",
      A200: "#ffbac5",
      A400: "#ff879b",
      A700: "#ff6e85",
      contrastDefaultColor: "light",
    },

    brick: {
      50: "#f7e4e6",
      100: "#ecbcc0",
      200: "#df8f96",
      300: "#d2626c",
      400: "#c8404d",
      500: "#be1e2d",
      600: "#b81a28",
      700: "#af1622",
      800: "#a7121c",
      900: "#990a11",
      A100: "#ffc7c9",
      A200: "#ff9497",
      A400: "#ff6165",
      A700: "#ff474d",
      contrastDefaultColor: "light",
    },

    plum: {
      50: "#ede6f2",
      100: "#d1c0de",
      200: "#b396c8",
      300: "#946cb2",
      400: "#7d4da2",
      500: "#662d91",
      600: "#5e2889",
      700: "#53227e",
      800: "#491c74",
      900: "#381162",
      A100: "#c598ff",
      A200: "#a965ff",
      A400: "#8c32ff",
      A700: "#7e19ff",
      contrastDefaultColor: "light",
    },

    midnight: {
      50: "#e5e4ec",
      100: "#bebdd0",
      200: "#9391b1",
      300: "#676491",
      400: "#47437a",
      500: "#262262",
      600: "#221e5a",
      700: "#1c1950",
      800: "#171446",
      900: "#0d0c34",
      A100: "#746fff",
      A200: "#423cff",
      A400: "#1109ff",
      A700: "#0700ef",
      contrastDefaultColor: "light",
    },

    ocean: {
      50: "#e0e6e9",
      100: "#b3c2c8",
      200: "#8099a3",
      300: "#4d707e",
      400: "#265163",
      500: "#003247",
      600: "#002d40",
      700: "#002637",
      800: "#001f2f",
      900: "#001320",
      A100: "#5daaff",
      A200: "#2a8fff",
      A400: "#0074f6",
      A700: "#0068dc",
      contrastDefaultColor: "light",
    },

    seafoam: {
      50: "#e0eaeb",
      100: "#b3cbce",
      200: "#80a8ad",
      300: "#4d858c",
      400: "#266b74",
      500: "#00515b",
      600: "#004a53",
      700: "#004049",
      800: "#003740",
      900: "#00272f",
      A100: "#68deff",
      A200: "#35d3ff",
      A400: "#02c8ff",
      A700: "#00b5e7",
      contrastDefaultColor: "light",
    },

    teal: {
      50: "#e3eeee",
      100: "#b8d4d5",
      200: "#89b7b9",
      300: "#599a9c",
      400: "#368587",
      500: "#126f72",
      600: "#10676a",
      700: "#0d5c5f",
      800: "#0a5255",
      900: "#054042",
      A100: "#78f9ff",
      A200: "#45f7ff",
      A400: "#12f5ff",
      A700: "#00edf7",
      contrastDefaultColor: "light",
    },

    skyblue: {
      50: "#e0f4fb",
      100: "#b3e4f5",
      200: "#80d2ef",
      300: "#4dc0e8",
      400: "#26b3e3",
      500: "#00a5de",
      600: "#009dda",
      700: "#0093d5",
      800: "#008ad1",
      900: "#0079c8",
      A100: "#f1f8ff",
      A200: "#bee1ff",
      A400: "#8bcaff",
      A700: "#71bfff",
      contrastDefaultColor: "light",
    },

    navy: {
      50: "#e3e7ef",
      100: "#b9c3d8",
      200: "#8a9bbe",
      300: "#5b73a4",
      400: "#375591",
      500: "#14377d",
      600: "#123175",
      700: "#0e2a6a",
      800: "#0b2360",
      900: "#06164d",
      A100: "#8196ff",
      A200: "#4e6bff",
      A400: "#1b41ff",
      A700: "#022bff",
      contrastDefaultColor: "light",
    },

    rose: {
      50: "#f8e3e9",
      100: "#edbac7",
      200: "#e18ca2",
      300: "#d45d7c",
      400: "#cb3b60",
      500: "#c21844",
      600: "#bc153e",
      700: "#b41135",
      800: "#ac0e2d",
      900: "#9f081f",
      A100: "#ffcbd1",
      A200: "#ff98a3",
      A400: "#ff6576",
      A700: "#ff4c5f",
      contrastDefaultColor: "light",
    },

    sunshine: {
      50: "#fffae8",
      100: "#fff2c6",
      200: "#ffeaa0",
      300: "#ffe279",
      400: "#ffdb5d",
      500: "#ffd540",
      600: "#ffd03a",
      700: "#ffca32",
      800: "#ffc42a",
      900: "#ffba1c",
      A100: "#ffffff",
      A200: "#ffffff",
      A400: "#ffefcd",
      A700: "#ffe6b3",
      contrastDefaultColor: "dark",
    },

    robinsegg: {
      50: "#e5f5f3",
      100: "#bee6e1",
      200: "#93d5cd",
      300: "#67c4b8",
      400: "#47b8a9",
      500: "#26ab9a",
      600: "#22a492",
      700: "#1c9a88",
      800: "#17917e",
      900: "#0d806c",
      A100: "#b2fff0",
      A200: "#7fffe6",
      A400: "#4cffdc",
      A700: "#32ffd8",
      contrastDefaultColor: "dark",
    },

    greenapple: {
      50: "#f6f9e6",
      100: "#e9f0c1",
      200: "#dae797",
      300: "#cbdd6d",
      400: "#c0d54e",
      500: "#b5ce2f",
      600: "#aec92a",
      700: "#a5c223",
      800: "#9dbc1d",
      900: "#8db012",
      A100: "#f8ffe0",
      A200: "#ebffad",
      A400: "#dfff7a",
      A700: "#d9ff61",
      contrastDefaultColor: "dark",
    },
  },
};
