import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  CircularProgress,
  Snackbar,
  Link,
  Typography,
  List,
} from "@material-ui/core";
import AccessTime from "@material-ui/icons/AccessTime";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Axios from "./utils/xhr";
import HelperText from "./HelperText";
import PasswordRequirement from "./PasswordRequirement";
import WaitButton from "./WaitButton";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "800px",
  },
  verifyRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "200px",
  },
  toolBody: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(3),
    width: "100%",
    "&>*": {
      flex: "1 1 50%",
      padding: theme.spacing(2),
      "&:last-child": {
        borderLeft: "solid 1px rgba(255,255,255)",
      },
    },
  },
});

class PasswordTool extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    email: PropTypes.string,
    userRealName: PropTypes.string,
    params: PropTypes.object,
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      passwordError: "",
      password: "",
      confirmPassword: "",
      confirmPasswordError: "",
      userRealName: null,
      username: null,
      waitingOnAuthService: false,
      passwordValidation: {
        length: false,
        partOfEmail: false,
        partOfFullName: false,
        lowerCaseLetter: false,
        upperCaseLetter: false,
        numeric: false,
        specialCharacter: false,
      },
      errorMessage: "",
      open: false,
    };
  }

  componentDidMount() {
    (async () => {
      try {
        let response = await Axios.get("/reset-password/verify", {
          params: {
            email: atob(decodeURIComponent(this.props.match.params.email)),
            token: this.props.match.params.token,
          },
          withCredentials: true,
        });
        this.setState({
          userRealName: response.data.displayName,
          username: response.data.username,
        });
      } catch (error) {
        this.setState({ userRealName: "" });
      }
    })();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.userRealName !== null;
  }

  checkPassword = (pswd) => {
    // set password variable
    let validSteps = [];
    let mustComply = [];

    //validate the length
    if (pswd.length < 10) {
      mustComply.push(false);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            length: false,
          },
        };
      });
    } else {
      mustComply.push(true);
      this.setState(function (lastState) {
        return {
          passwordValidation: { ...lastState.passwordValidation, length: true },
        };
      });
    }

    //validate letter
    if (pswd.match(/[a-z]/)) {
      validSteps.push(true);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            lowerCaseLetter: true,
          },
        };
      });
    } else {
      validSteps.push(false);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            lowerCaseLetter: false,
          },
        };
      });
    }

    //validate uppercase letter
    if (pswd.match(/[A-Z]/)) {
      validSteps.push(true);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            upperCaseLetter: true,
          },
        };
      });
    } else {
      validSteps.push(false);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            upperCaseLetter: false,
          },
        };
      });
    }

    //validate no email
    if (
      !this.containsPortionOfName(pswd, this.state.username, 3) &&
      pswd !== ""
    ) {
      mustComply.push(true);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            partOfEmail: true,
          },
        };
      });
    } else {
      mustComply.push(false);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            partOfEmail: false,
          },
        };
      });
    }

    //validate no name
    if (
      !this.containsPortionOfName(pswd, this.state.userRealName, 3) &&
      pswd !== ""
    ) {
      mustComply.push(true);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            partOfFullName: true,
          },
        };
      });
    } else {
      mustComply.push(false);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            partOfFullName: false,
          },
        };
      });
    }

    //validate number
    if (pswd.match(/[0-9]/)) {
      validSteps.push(true);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            numeric: true,
          },
        };
      });
    } else {
      validSteps.push(false);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            numeric: false,
          },
        };
      });
    }

    //validate special character
    if (pswd.match(/[`~!@#$%^&*()_+[\]\\;',./{}|:"<>?]/)) {
      validSteps.push(true);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            specialCharacter: true,
          },
        };
      });
    } else {
      validSteps.push(false);
      this.setState(function (lastState) {
        return {
          passwordValidation: {
            ...lastState.passwordValidation,
            specialCharacter: false,
          },
        };
      });
    }

    if (!this.isValidPassword(mustComply, validSteps)) {
      this.setState({
        passwordError: "Password doesn't meet policy requirements",
      });
    } else {
      this.setState({ passwordError: "" });
    }
  };

  isValidPassword = (mustComply, countComply) => {
    for (let i = 0; i < mustComply.length; i++) {
      if (!mustComply[i]) {
        return false;
      }
    }

    let countMustEqual = 3;
    let countCurrent = 0;
    for (let j = 0; j < countComply.length; j++) {
      if (countComply[j]) {
        countCurrent++;
      }
    }

    return countCurrent >= countMustEqual;
  };

  containsPortionOfName = (input = "", haystack = "", consecutiveCharCount) => {
    //Start Pos (index 0 based)
    let s = 0;

    //lowercase & split words to separate checks
    input = input.toLowerCase();
    haystack = haystack.toLowerCase().split(" ");

    for (let h = 0; h < haystack.length; h++) {
      for (s = 0; s < haystack[h].length - consecutiveCharCount; s++) {
        if (input.indexOf(haystack[h].substr(s, consecutiveCharCount)) !== -1) {
          return true;
        }
      }
    }
    return false;
  };

  changePassword = (e) => {
    let passwordText = e.target.value;
    this.setState({ password: passwordText });
    this.checkPassword(passwordText);
  };

  changeConfirmPassword = (e) => {
    let passwordText = e.target.value;
    this.setState({ confirmPassword: passwordText });
    if (passwordText === this.state.password) {
      this.setState({ confirmPasswordError: "" });
    } else {
      this.setState({ confirmPasswordError: "Passwords do not match." });
    }
  };

  canChangePassword = () => {
    return (
      this.state.passwordError !== "" ||
      this.state.confirmPasswordError !== "" ||
      this.state.confirmPassword !== this.state.password ||
      this.state.password === "" ||
      this.state.confirmPassword === ""
    );
  };

  savePassword = async () => {
    try {
      this.setState({ waitingOnAuthService: true });
      await Axios.post("/reset-password", {
        email: atob(decodeURIComponent(this.props.match.params.email)),
        password: this.state.password,
        token: this.props.match.params.token,
      });
    } catch (error) {
      //Show error
      if (error.response) {
        if (error.response.data.friendlyMessage) {
          this.setState({
            open: true,
            errorMessage: error.response.data.friendlyMessage,
            waitingOnAuthService: false,
          });
        } else {
          switch (error.response.data.result) {
            default:
              this.setState({
                open: true,
                errorMessage:
                  "Unable to save password. Make sure the password is a new password and try again.",
                waitingOnAuthService: false,
              });
              break;
          }
        }
      } else if (error.request) {
        this.setState({
          open: true,
          errorMessage:
            "Unable to access authentication service. Please check your internet connection and try again.",
          waitingOnAuthService: false,
        });
      } else {
        //Javascript error?
        this.setState({
          open: true,
          errorMessage: "An unspecified browser error occurred.",
          waitingOnAuthService: false,
        });
      }
      return;
    }
    //Auto login
    try {
      await Axios.post(
        "/login",
        {
          email: atob(decodeURIComponent(this.props.match.params.email)),
          password: btoa(this.state.password),
        },
        { withCredentials: true }
      );
      window.location = "/home";
    } catch (error) {
      //Auto Login failed go to login page.
      this.props.history.push("/login");
    }
  };

  onSnackbarRequestClose = () => {
    this.setState({ open: false });
  };

  enterKeyHandler = (e) => {
    if (e.keyCode === 13 && !this.canChangePassword()) {
      this.savePassword();
    }
  };

  checkForCapsLock = (type) => (e) => {
    const CAPS_ON = e.getModifierState && e.getModifierState("CapsLock");
    if (CAPS_ON) {
      if (type === "confirm") {
        this.setState({ confirmPasswordError: "Warning - Caps Lock is On!" });
      } else {
        this.setState({ passwordError: "Warning - Caps Lock is On!" });
      }
    } else {
      if (type === "confirm") {
        this.setState({ confirmPasswordError: "" });
      } else {
        this.setState({ passwordError: "" });
      }
    }
  };

  render() {
    switch (this.state.userRealName) {
      case null:
        return (
          <div className={this.props.classes.verifyRoot}>
            <CircularProgress size={80} />
            <Typography
              variant="h3"
              style={{ marginTop: "20px", fontWeight: "300" }}
            >
              <small>Verifying</small>
            </Typography>
          </div>
        );
      case "":
        return (
          <div className={this.props.classes.verifyRoot}>
            <Typography
              variant="h1"
              color="primary"
              style={{ fontSize: "72px" }}
            >
              <AccessTime fontSize="inherit" />
            </Typography>
            <Typography variant="h2">
              <small>This link has expired.</small>
            </Typography>
            <Typography>
              Please{" "}
              <Link
                component={RouterLink}
                to={"/forgot-password"}
                style={{ textDecoration: "underline" }}
              >
                request
              </Link>{" "}
              a new link
            </Typography>
          </div>
        );
      default:
        return (
          <React.Fragment>
            <div className={this.props.classes.root}>
              <Typography color="primary">
                Please set and confirm your new password.
              </Typography>
              <div className={this.props.classes.toolBody}>
                <div>
                  <TextField
                    label="Password"
                    type="password"
                    fullWidth={true}
                    autoFocus={true}
                    value={this.state.password}
                    helperText={
                      <HelperText message={this.state.passwordError} />
                    }
                    onChange={this.changePassword}
                    onKeyDown={this.checkForCapsLock("")}
                    onKeyUp={this.enterKeyHandler}
                  />
                  <TextField
                    label="Confirm Password"
                    type="password"
                    fullWidth={true}
                    style={{ marginTop: "10px" }}
                    value={this.state.confirmPassword}
                    disabled={
                      this.state.passwordError !== "" ||
                      this.state.password === ""
                    }
                    helperText={
                      <HelperText message={this.state.confirmPasswordError} />
                    }
                    onKeyDown={this.checkForCapsLock("confirm")}
                    onChange={this.changeConfirmPassword}
                    onKeyUp={this.enterKeyHandler}
                  />
                  <div>
                    <WaitButton
                      variant="contained"
                      waiting={this.state.waitingOnAuthService}
                      onClick={this.savePassword}
                      disabled={this.canChangePassword()}
                      color="primary"
                    >
                      Save
                    </WaitButton>
                  </div>
                </div>
                <div>
                  <Typography variant="subtitle2" color="primary">
                    Your password must meet the following requirements:
                  </Typography>
                  <List dense={true}>
                    <PasswordRequirement
                      pass={this.state.passwordValidation.partOfEmail}
                      description="Not contain parts of your email that exceed two
                      consecutive characters."
                    />
                    <PasswordRequirement
                      pass={this.state.passwordValidation.partOfFullName}
                      description="Not contain parts of your full name that exceed two
                      consecutive characters."
                    />
                    <PasswordRequirement
                      pass={this.state.passwordValidation.length}
                      description="Be at least 10 characters in length."
                    />
                  </List>
                  <Typography variant="subtitle2" color="primary">
                    And meet at least 3 of these requirements:
                  </Typography>
                  <List dense={true}>
                    <PasswordRequirement
                      pass={this.state.passwordValidation.lowerCaseLetter}
                      description="At least one lowercase letter (a-z)"
                    />
                    <PasswordRequirement
                      pass={this.state.passwordValidation.upperCaseLetter}
                      description="At least one uppercase letter (A-Z)"
                    />
                    <PasswordRequirement
                      pass={this.state.passwordValidation.numeric}
                      description="At least one number (0-9)"
                    />
                    <PasswordRequirement
                      pass={this.state.passwordValidation.specialCharacter}
                      description="At least one special character"
                    />
                  </List>
                  <Typography variant="caption" color="textSecondary">
                    Note: Your password also may not be one of the last 12
                    passwords you have used.
                  </Typography>
                </div>
              </div>
            </div>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.open}
              onClose={this.onSnackbarRequestClose}
              autoHideDuration={4000}
              message={
                <span id="message-id" style={{ textAlign: "center" }}>
                  {this.state.errorMessage}
                </span>
              }
            />
          </React.Fragment>
        );
    }
  }
}

export default withRouter(withStyles(styles)(PasswordTool));
