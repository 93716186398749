import axios from "axios";

const axiosClient = axios.create({
  baseURL:
    window.location.protocol +
    "//" +
    (window.location.hostname === "localhost"
      ? "localhost:9000"
      : window.location.hostname + "/api/v1/auth"),
  headers: { "Cache-Control": "no-store" }
});

export default axiosClient;
