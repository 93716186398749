import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function WaitButton(props) {
  const classes = useStyles();
  const { waiting, children, disabled, ...rest } = props;
  return (
    <span className={classes.wrapper}>
      <Button disabled={disabled || waiting} color="default" {...rest}>
        {children}
      </Button>
      {waiting && (
        <CircularProgress
          size={24}
          className={classes.buttonProgress}
          variant="indeterminate"
        />
      )}
    </span>
  );
}
