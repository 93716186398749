import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Logo from "./Logo";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import PasswordTool from "./PasswordTool";
import { Paper, Typography } from "@material-ui/core";
import Axios from "axios";
import { Collapse, Link } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
  "@global": {
    html: {
      minHeight: "100%",
      height: "100%",
      overflow: "hidden",
      background: theme.palette.background.default,
    },
    body: {
      height: "100%",
      background: theme.palette.background.default,
    },
    "#root": {
      height: "100%",
    },
    "input:-webkit-autofill": {
      "box-shadow": "0 0 0 50px #005654 inset",
      "text-fill-color": "#ffffff",
    },
    "input:-webkit-autofill:focus": {
      "box-shadow": "0 0 0 50px #005654 inset",
      "text-fill-color": "#ffffff",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  logo: {
    maxWidth: "350px",
    height: "50px",
    marginRight: theme.spacing(1),
  },
  healthMessage2: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    padding: "10px 5%",
    textAlign: "center",
    background: theme.palette.info.main,
    color: "#fffffff",
    maxHeight: "100px",
    overflow: "auto",
  },
  healthMessageText: {
    color: theme.palette.info.contrastText,
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    "&:last-child": {
      marginLeft: theme.spacing(1),
    },
  },
  supportFooter: {
    margin: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    textAlign: "center",
    "& > a": {
      color: "#ffffff",
    },
  },
  divider: {
    margin: theme.spacing(2),
  },

  copyright: {
    marginTop: theme.spacing(2),
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      announcementMessage: null,
    };
  }

  componentDidMount() {
    Axios.get("/api/v1/auth/announcement")
      .then((res) => {
        if (res.data) {
          this.setState({
            announcementMessage: Array.isArray(res.data)
              ? res.data[0]
              : res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response.data) {
          this.setState({ announcementMessage: err.response.data });
        }
      });
  }

  render() {
    const { props } = this;
    return (
      <div className={props.classes.root}>
        <Divider className={props.classes.divider} />
        <Collapse in={Boolean(this.state?.announcementMessage?.message)}>
          <Paper className={props.classes.healthMessage2} square>
            <Typography
              variant="caption"
              className={props.classes.healthMessageText}
            >
              {this.state.announcementMessage
                ? this.state.announcementMessage.message
                : ""}
            </Typography>
          </Paper>
        </Collapse>
        <div className={props.classes.logoWrapper}>
          <Logo className={props.classes.logo} />
        </div>

        <Router
          basename={
            document.location.hostname === "localhost" ? "/" : "/identity"
          }
        >
          <Switch>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-password/user/:email/token/:token">
              <PasswordTool />
            </Route>
            <Route path="/register/user/:email/invite/:token">
              <PasswordTool />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Router>
        <Divider className={props.classes.divider} />
        <div className={this.props.classes.supportFooter}>
          <Link
            id="community-portal"
            href="http://communities.nthrive.com"
            target="_blank"
          >
            Community Portal
          </Link>
          <Typography component="span" color="textSecondary" variant="body2">
            {" "}
            |{" Customer Support: "}
          </Typography>
          <Link href="tel:8003907459">800-390-7459</Link>
          <Typography
            component="div"
            variant="caption"
            color="textPrimary"
            style={{ textAlign: "center" }}
            className={props.classes.copyright}
          >
            &copy; {new Date().getFullYear()}, FinThrive, Inc. and its
            affiliates and subsidiaries (collectively, “FinThrive”). All Rights
            Reserved
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(App);
