import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import CheckBoxOutline from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBox from "@material-ui/icons/CheckBox";

export default function PasswordRequirement(props) {
  return (
    <ListItem disabled={!props.pass}>
      <ListItemIcon>
        {props.pass ? <CheckBox color="primary" /> : <CheckBoxOutline />}
      </ListItemIcon>
      <ListItemText
        primary={props.description}
        primaryTypographyProps={{
          variant: "caption",
        }}
      />
    </ListItem>
  );
}
