import React from "react";
import { createTheme } from "@material-ui/core/styles";
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import themes from "./themes";
import App from "./App";

const theme = createTheme(
  localStorage
    ? themes[localStorage.getItem("theme")] || themes.light
    : themes.light
);

const generateClassName = createGenerateClassName({
  productionPrefix: "login",
  disableGlobal: true,
});

class AppWrapper extends React.PureComponent {
  render() {
    const { props } = this;
    return (
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App {...props} />
        </ThemeProvider>
      </StylesProvider>
    );
  }
}

export default AppWrapper;
