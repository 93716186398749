import React from "react";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

export default function HelperText(props) {
  return props.message && props.message !== "" ? (
    <span>
      <ErrorOutline
        style={{
          height: "18px",
          width: "18px",
          verticalAlign: "middle"
        }}
      />{" "}
      {props.message}
    </span>
  ) : (
    <span style={{ height: "19px", display: "block" }}>&nbsp;</span>
  );
}
