import React from "react";
import {
  Typography,
  TextField,
  Link,
  Slide,
  Snackbar,
  Button,
} from "@material-ui/core";
import WaitButton from "./WaitButton";
import { Link as RouterLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Axios from "./utils/xhr";
import HelperText from "./HelperText";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "350px",
    width: "100%",
    textAlign: "center",
    "&>*": {
      margin: theme.spacing(1, 0, 1, 0),
    },
  },
  goBackLink: {
    fontFamily: theme.typography.fontFamily,
  },
});

class ForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      open: false,
      waitingForAuthService: false,
      snackbarMessage: "",
    };
  }

  onSnackbarRequestClose = () => {
    this.setState({ open: false });
  };

  send = async () => {
    this.setState({ waitingForAuthService: true });
    let validationError = false;

    const { email } = this.state;

    if (email === "") {
      this.setState({
        emailError: "Please enter your email.",
        waitingForAuthService: false,
      });
      validationError = true;
    }

    if (email.indexOf("@nthrive.com") > -1) {
      return;
    }

    let match = email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!match) {
      this.setState({
        emailError: "Please enter a valid email.",
        waitingForAuthService: false,
      });
      validationError = true;
      return;
    }

    if (!validationError) {
      try {
        let response = await Axios.post("/forgot-password", {
          email: this.state.email,
        });
        if (response.data.result === "Reset Success") {
          this.setState({
            open: true,
            snackbarMessage:
              "Password reset link has been sent. Please check your email.",
            email: "",
            waitingForAuthService: false,
          });
        }
      } catch (error) {
        if (error.response) {
          if (error.response.data.friendlyMessage) {
            this.setState({
              open: true,
              snackbarMessage: error.response.data.friendlyMessage,
              email: "",
              waitingForAuthService: false,
            });
          } else {
            switch (error.response.data.result) {
              default:
                this.setState({
                  open: true,
                  snackbarMessage:
                    "Email does not exist. Please contact your account manager.",
                  email: "",
                  waitingForAuthService: false,
                });
                break;
            }
          }
        } else if (error.request) {
          this.setState({
            open: true,
            snackbarMessage:
              "Unable to access authentication service. Please check your internet connection.",
            waitingForAuthService: false,
          });
        } else {
          //Javascript error?
          this.setState({
            open: true,
            snackbarMessage: "An unspecified browser error occurred.",
            waitingForAuthService: false,
          });
        }
      }
    } else {
      this.setState({
        open: true,
        snackbarMessage: "Please provide an email.",
        waitingForAuthService: false,
      });
    }
  };

  changeEmail = (e) => {
    let value = e.target.value;
    if (
      value.toLowerCase().indexOf("@nthrive.com") > -1 ||
      value.toLowerCase().indexOf("@finthrive.com") > -1
    ) {
      this.setState({
        email: value,
        emailError: (
          <span>
            Please reset your account at{" "}
            <Link
              href="https://reset.finthrive.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              https://reset.finthrive.com/
            </Link>
            .
          </span>
        ),
      });
    } else {
      this.setState({
        email: value,
        emailError: "",
      });
    }
  };

  enterKeyHandler = (e) => {
    if (
      this.state.emailError ||
      this.state.email === "" ||
      this.state.email.indexOf("@nthrive") > -1 ||
      this.state.email.indexOf("@finthrive") > -1
    ) {
      return;
    } else if (e.keyCode === 13) {
      this.send();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className={this.props.classes.root}>
          <Typography color="primary">
            Enter your email to reset your password.
          </Typography>
          <TextField
            id="forgot-password-email"
            label="Email"
            value={this.state.email}
            onChange={this.changeEmail}
            onKeyUp={this.enterKeyHandler}
            autoFocus={true}
            fullWidth
            autoCorrect="off"
            autoCapitalize="off"
            autoComplete="off"
            helperText={<HelperText message={this.state.emailError} />}
          />
          <div style={{ paddingTop: "0px" }}>
            <WaitButton
              id="request-link"
              waiting={this.state.waitingForAuthService}
              variant="contained"
              onClick={this.send}
              fullWidth
              disabled={this.state.emailError || this.state.email === ""}
              color="primary"
            >
              Request Link
            </WaitButton>
          </div>
          <Link
            id="go-back-to-login"
            className={this.props.classes.goBackLink}
            component={RouterLink}
            to={"/login" + document.location.search}
          >
            &lt; Go to Sign In Page
          </Link>
        </div>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.open}
          TransitionComponent={(props) => <Slide {...props} direction="down" />}
          onClose={this.onSnackbarRequestClose}
          action={[
            <Button
              key="close"
              size="small"
              onClick={this.onSnackbarRequestClose}
              style={{ color: "black" }}
            >
              Close
            </Button>,
          ]}
          message={
            <span id="message-id" style={{ textAlign: "center" }}>
              {this.state.snackbarMessage}
            </span>
          }
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
