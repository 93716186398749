import { alpha, darken } from "@material-ui/core/styles";
import colors from "./utils/themeColors";

const THEMES = {
  light: {
    palette: {
      type: "light",
      primary: {
        main: colors.Mint,
      },
      secondary: {
        main: colors.DarkCoral,
      },
      error: {
        main: colors.Supplemental.Error,
        contrastText: "#ffffff",
      },
      warning: {
        main: colors.Supplemental.Warning,
        contrastText: "#ffffff",
      },
      info: {
        main: colors.Supplemental.Info,
        contrastText: "#ffffff",
      },
      success: {
        main: colors.Supplemental.Success,
        contrastText: "#ffffff",
      },
      text: {
        primary: "#ffffff",
        secondary: colors.Grey.v8,
        disabled: colors.Grey.v3,
      },
      background: {
        paper: "#ffffff",
        default: colors.Grun,
      },
      divider: colors.AccentCoral,
      action: {
        hoverOpacity: 0.04,
      },
    },
    typography: {
      fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
      h1: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "2.2rem",
        fontWeight: 400,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      h2: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "1.8rem",
        fontWeight: 400,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      h3: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "1.4rem",
        fontWeight: 400,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      h4: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "1.2rem",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      h5: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "1.1rem",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      h6: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "1.0rem",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      subtitle1: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      subtitle2: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      body1: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontWeight: 400,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      body2: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontWeight: 400,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      caption: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      overline: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontFeatureSettings: "'liga' off",
      },
    },
    props: {
      MuiButton: {
        color: "primary",
      },
      MuiTextField: {
        variant: "outlined",
      },
      MuiCircularProgress: {
        color: "secondary",
      },
    },

    overrides: {
      MuiDivider: {
        root: {
          width: "50%",
          height: "4px",
        },
      },
      MuiButton: {
        contained: {
          borderRadius: "18px",
          textTransform: "none",
        },
      },
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: "#ffffff",
          },
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            "&:hover $notchedOutline": {
              borderColor: "#ffffff",
            },
          },
          "&$focused $notchedOutline": {
            borderColor: "#ffffff",
            borderWidth: 2,
          },
        },
        notchedOutline: {
          borderColor: alpha("#ffffff", 0.5),
        },
      },
      MuiFormLabel: {
        root: {
          "&$focused": { color: "#ffffff" },
        },
      },
      MuiAppBar: {
        colorDefault: { backgroundColor: "#ffffff", color: colors.Text },
      },
    },
  },
  dark: {
    palette: {
      type: "dark",
      primary: {
        main: colors.Mint,
      },
      secondary: {
        main: colors.DarkCoral,
      },
      error: {
        main: colors.Supplemental.Error,
        contrastText: "#ffffff",
      },
      warning: {
        main: colors.Supplemental.Warning,
        contrastText: "#ffffff",
      },
      info: {
        main: colors.Supplemental.Info,
        contrastText: "#ffffff",
      },
      success: {
        main: colors.Supplemental.Success,
        contrastText: "#ffffff",
      },
      text: {
        primary: "#ffffff",
        secondary: colors.Grey.v5,
        disabled: colors.Grey.v3,
      },
      background: {
        default: colors.Grun,
      },
      divider: colors.AccentCoral,
      action: {
        hoverOpacity: 0.04,
      },
    },
    typography: {
      fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
      h1: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "2.2rem",
        fontWeight: 400,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      h2: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "1.8rem",
        fontWeight: 400,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      h3: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "1.4rem",
        fontWeight: 400,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      h4: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "1.2rem",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      h5: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "1.1rem",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      h6: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontSize: "1.0rem",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      subtitle1: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      subtitle2: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      body1: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontWeight: 400,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      body2: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontWeight: 400,
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      caption: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        letterSpacing: "-0.02em",
        fontFeatureSettings: "'liga' off",
      },
      overline: {
        fontFamily: '"Plus Jakarta Sans","Arial", sans-serif',
        fontFeatureSettings: "'liga' off",
      },
    },
    props: {
      MuiButton: {
        color: "primary",
      },
      MuiTextField: {
        variant: "outlined",
      },
      MuiCircularProgress: {
        color: "secondary",
      },
    },

    overrides: {
      MuiDivider: {
        root: {
          width: "50%",
          height: "4px",
        },
      },
      MuiButton: {
        contained: {
          borderRadius: "18px",
          textTransform: "none",
        },
      },
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: "#ffffff",
          },
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            "&:hover $notchedOutline": {
              borderColor: "#ffffff",
            },
          },
          "&$focused $notchedOutline": {
            borderColor: "#ffffff",
            borderWidth: 2,
          },
        },
        notchedOutline: {
          borderColor: alpha("#ffffff", 0.5),
        },
      },
      MuiFormLabel: {
        root: {
          "&$focused": { color: "#ffffff" },
        },
      },
      MuiAppBar: {
        colorDefault: { backgroundColor: "#ffffff", color: colors.Text },
      },
    },
  },
};

if (!window.FinThriveAnalyticsPlatform) {
  window.FinThriveAnalyticsPlatform = {};
}

window.FinThriveAnalyticsPlatform.themes = THEMES;
window.FinThriveAnalyticsPlatform.colors = colors;

export default THEMES;
