import React from "react";
import { withStyles } from "@material-ui/styles";
import {
  TextField,
  Button,
  Link,
  Collapse,
  Snackbar,
  Slide,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import { Link as RouterLink, withRouter } from "react-router-dom";
import WaitButton from "./WaitButton";
import Axios from "./utils/xhr";
import AxiosRaw from "axios";
import HelperText from "./HelperText";
import qs from "qs";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import colors from "./utils/themeColors";

const styles = (theme) => ({
  root: {
    maxWidth: "350px",
    width: "100%",
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
    "&>*": {
      margin: theme.spacing(0.5, 0, 0.5, 0),
      "&:last-child": {
        margin: theme.spacing(3, 0, 3, 0),
        textAlign: "center",
      },
    },
  },
  healthMessage: {
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    background: colors.Supplemental.Error,
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    "&>:first-child": {
      marginRight: theme.spacing(1),
    },
  },
  forgotPassword: {
    marginTop: 0,
    paddingTop: 0,
    textAlign: "right",
  },
});

class Login extends React.PureComponent {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      showLogin: true,
      isAuthenticating: false,
      email: "",
      password: "",
      open: false,
      emailError: "",
      passwordError: "",
      healthCheckFailed: false,
    };
  }

  componentDidMount() {
    Axios.get("/health").catch(() => {
      this.setState({ healthCheckFailed: true });
    });
  }

  handleLogin = () => {
    let validationError = false;
    if (this.state.email === "" || this.state.emailError !== "") {
      validationError = true;
      if (this.state.emailError === "") {
        this.setState({ emailError: "Please enter your email." });
      }
    }

    if (this.state.password === "") {
      this.setState({ passwordError: "Please enter your password." });
      validationError = true;
    }

    if (validationError) {
      return;
    }

    this.setState({ isAuthenticating: true });
    let request;
    if (this.props.history.location.pathname.indexOf("account-link") > -1) {
      request = AxiosRaw.post(
        `/identity/link`,
        {
          email: this.state.email,
          password: btoa(this.state.password),
        },
        {
          withCredentials: true,
        }
      ).then(() => {
        window.location = "/identity/authenticate";
      });
    } else {
      request = Axios.post(
        "/login",
        {
          email: this.state.email,
          password: btoa(this.state.password),
        },
        {
          withCredentials: true,
        }
      ).then(() => {
        const query = qs.parse(this.props.location.search.replace("?", ""));
        if (query.redirect && query.redirect.indexOf("/admin/logout") === -1) {
          window.location = query.redirect;
        } else {
          window.location = `${document.location.protocol}//${document.location.hostname}/home`;
        }
        this.setState({ showLogin: false, isAuthenticating: false });
      });
    }
    request.catch((error) => {
      if (error.response) {
        if (error.response.data.friendlyMessage) {
          this.setState({
            open: true,
            errorMessage: error.response.data.friendlyMessage,
            password: "",
            isAuthenticating: false,
          });
        } else {
          switch (error.response.data.result) {
            case "Invalid Request IP":
              this.setState({
                open: true,
                errorMessage:
                  "You are attempting to use FinThrive Analyze outside of your organization's trusted network. Please connect to your trusted network to continue.",
                password: "",
                isAuthenticating: false,
              });
              break;
            case "Password Expired":
              Axios.post("/forgot-password", {
                email: this.state.email,
              })
                .then(() => {
                  this.setState({
                    open: true,
                    errorMessage:
                      "Per your Organization's sercurity policy, your password has expired. Check your email for a reset password link.",
                    password: "",
                    isAuthenticating: false,
                  });
                })
                .catch((ex) => {
                  this.setState({
                    open: true,
                    errorMessage:
                      "Your password has expired, but we were unable to automatically generate a link.  Click Reset Password below.",
                    isAuthenticating: false,
                  });
                });
              break;
            case "Dormant Account":
              Axios.post("/forgot-password", {
                email: this.state.email,
              })
                .then(() => {
                  this.setState({
                    open: true,
                    errorMessage:
                      "Your account has been dormant for more days allowed by your organization. Check your email for a reset password link.",
                    password: "",
                    isAuthenticating: false,
                  });
                })
                .catch((ex) => {
                  this.setState({
                    open: true,
                    errorMessage:
                      "Your account has been dormant for more days allowed by your organization, but we were unable to automatically generate a link.  Click Reset Password below.",
                    isAuthenticating: false,
                  });
                });
              break;
            case "Account Disabled":
              this.setState({
                open: true,
                errorMessage:
                  "Your account is disabled. Contact your account manager.",
                password: "",
                isAuthenticating: false,
              });
              break;
            case "A4 Sync Error":
            case "Service Error":
              this.setState({
                open: true,
                errorMessage: (
                  <span>
                    A service error has occurred please try again or{" "}
                    <a href="tel:+8003907459">contact support</a>:
                    1.800.390.7459.
                  </span>
                ),
                isAuthenticating: false,
              });
              break;
            case "Invalid Login":
            case "Account Doesn't Exist":
            case "Invalid Password":
            default:
              this.setState({
                open: true,
                errorMessage: (
                  <span>
                    Incorrect authentication credentials. You can{" "}
                    <Link
                      component={RouterLink}
                      to={"/forgot-password" + document.location.search}
                      color="inherit"
                    >
                      reset your account
                    </Link>{" "}
                    or <a href="tel:+8003907459">contact us</a>: 1.800.390.7459
                    for help.
                  </span>
                ),
                password: "",
                isAuthenticating: false,
              });
              break;
          }
        }
      } else if (error.request) {
        this.setState({
          open: true,
          errorMessage: (
            <span>
              Unable to access authentication service. Please check your
              internet connection.
            </span>
          ),
          isAuthenticating: false,
        });
      } else {
        //Javascript error?
        this.setState({
          open: true,
          errorMessage: "An unspecified browser error occurred.",
          isAuthenticating: false,
        });
      }
    });
  };

  handleTextInput = (stateKey) => (e) => {
    //Caps Lock Check function will clear message on key down instead for passwords
    if (stateKey === "password") {
      this.setState({ [stateKey]: e.target.value });
    } else {
      this.setState({ [stateKey]: e.target.value, [stateKey + "Error"]: "" });
    }
  };

  checkForCapsLock = (e) => {
    const CAPS_ON = e.getModifierState && e.getModifierState("CapsLock");
    if (CAPS_ON) {
      this.setState({ passwordError: "Warning - Caps Lock is On!" });
    } else {
      this.setState({ passwordError: "" });
    }
  };

  handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.handleLogin();
    }
  };

  onSnackbarRequestClose = () => {
    this.setState({ open: false });
  };

  validateEmailAddress = () => {
    const { email } = this.state;
    if (email === "") {
      return;
    }
    let match = email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!match) {
      this.setState({ emailError: "Please enter a valid email." });
    }
  };

  render() {
    const linkAccount =
      this.props.history.location.pathname.indexOf("account-link") > -1;
    console.log(this.props.history);
    return (
      <React.Fragment>
        <div className={this.props.classes.root}>
          <Collapse in={this.state.healthCheckFailed}>
            <Paper className={this.props.classes.healthMessage}>
              <ErrorOutlineIcon
                style={{ color: "#ffffff", fontSize: "48px" }}
              />
              <Typography variant="body2">
                We are currently experiencing technical difficulties and we may
                not be able to access your account right now.
              </Typography>
            </Paper>
          </Collapse>
          <div className={this.props.classes.loginForm}>
            <TextField
              id="email"
              label="Email"
              fullWidth
              autoFocus
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
              value={this.state.email}
              onChange={this.handleTextInput("email")}
              onKeyUp={this.handleEnterKey}
              onBlur={this.validateEmailAddress}
              helperText={<HelperText message={this.state.emailError} />}
            />
            <TextField
              label="Password"
              type="password"
              id="password"
              fullWidth
              autoCorrect="off"
              autoCapitalize="off"
              value={this.state.password}
              onChange={this.handleTextInput("password")}
              onKeyDown={this.checkForCapsLock}
              onKeyUp={this.handleEnterKey}
              helperText={<HelperText message={this.state.passwordError} />}
            />
            <div className={this.props.classes.forgotPassword}>
              <Link
                id="forgot-password-link"
                component={RouterLink}
                to={!linkAccount ? "/forgot-password" : "/login"}
                style={{
                  textDecoration: "underline",
                  color: "white",
                }}
              >
                {!linkAccount ? "Forgot Password?" : "Return to Sign In"}
              </Link>
            </div>
            <div>
              <div style={{ textAlign: "center" }}>
                <WaitButton
                  variant="contained"
                  onClick={this.handleLogin}
                  waiting={this.state.isAuthenticating}
                  color="primary"
                >
                  {linkAccount ? "Link Account" : "Sign In"}
                </WaitButton>
              </div>
              {!linkAccount && (
                <Divider
                  style={{
                    margin: "24px 0px",
                    width: "100%",
                    height: "1px",
                    background: "#ffffff",
                  }}
                />
              )}
              {!linkAccount && (
                <div>
                  <WaitButton
                    variant="contained"
                    component="a"
                    href="/identity/authenticate"
                    color="primary"
                  >
                    Sign In with FinThrive Connect SSO
                  </WaitButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.open}
          TransitionComponent={(props) => <Slide {...props} direction="down" />}
          TransitionProps={{ unmountOnExit: true }}
          onClose={this.onSnackbarRequestClose}
          action={[
            <Button
              key="close"
              size="small"
              onClick={this.onSnackbarRequestClose}
              style={{ color: "black" }}
              id="close-login-snackbar"
            >
              Close
            </Button>,
          ]}
          message={
            <span id="message-id" style={{ textAlign: "center" }}>
              {this.state.errorMessage}
            </span>
          }
        />
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Login));
